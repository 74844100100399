/*
Author: 301
Author URI: https://301.com.co
Description: Tema creado a medida para el sitio web de ABAD.
Version: 1.0
License: Todos los derechos reservados
*/

:root
{
    --301-blue: #232a35;
    --301-red: #d7282f;
    --301-white: #ffffff;
    --301-beige: #f3f1e6;
    --301-black-35: rgb(34 34 34 / 25%);
    --301-white-30: rgb(255 255 255 / 30%);
    --301-gray: #a5a6a3;
    --301-gray-clean: #fafafa;
    --301-text-size: 14px;
    --301-text-table-size: 10px;
    --301-text-small-size: 12px;
    --301-text-medium-size: 24px;    
    --301-text-title-size: 34px;    
    --301-text-title-small-size: 20px;    
    --301-text-big-size: 44px;
    --301-transition: all .5s;
}

@font-face 
{
    src: url('../public/font/Arsenal-Regular.ttf');
    font-family: '--301-bold';
}

@font-face 
{
    src: url('../public/font/Gilroy-Light.ttf');
    font-family: '--301-light';
}

h1, h2, h3, h4
{
    font-family: --301-bold;
    text-transform: uppercase;
    font-weight: 100;
    font-size: var(--301-text-title-size);
    margin: 0;
}

p, label, span, li, a, button, input[type=file]
{
    color: var(--301-blue);
    font-family: --301-light;
    font-size: var(--301-text-size);
    text-decoration: none;
    line-height: 1.3;
}

a,
a i
{
    transition: var(--301-transition);
}

ul
{
    margin: 0 0 0 20px;
    padding: 0;
}

ul li
{
    list-style-image: url(../public/img/301_bullet.svg);
    padding-inline-start: 1ch;
    margin: 10px 0; 
}

input[type=text],
input[type=password],
input[type=email],
input[type=number],
select,
textarea
{
    font-family: --301-light;
    color: var(--301-gray);
    border: 1px solid var(--301-gray);
    font-size: var(--301-text-size);
    padding: 8px 4%;
    border-radius: 4px;
    width: 100%;
    height: 35px;
    box-sizing: border-box;
}

input::placeholder
{
    color: var(--301-gray);
    opacity: 1;
    font-size: var(--301-text-size);
}

._301_text h2 a._301_cta_h2{
    font-weight: var(--301-text-medium-size);
    font-family: --301-bold;
    font-size:24px;
}

button._301_cta, 
._301_form_send input[type=button]
{
    background: var(--301-red);
    padding: 8px 0;
    border-radius: 50px;
    transition: var(--301-transition);
    cursor: pointer;
    text-align: center;
    color: var(--301-white);
    border: 0;
    border: 1px solid var(--301-red);
    width: 100%;
    font-family: --301-light;
    font-size: var(--301-text-size);
}

button._301_cta i
{
    margin: 0 5px 0 0;
}

button._301_cta:hover,
button._301_cta._301_alter:hover
{
    color: var(--301-red);
    background: var(--301-white);
    border: 1px solid var(--301-red);
}

button._301_cta._301_alter
{
    color: var(--301-blue);
    background-color: var(--301-white);
    border-color: var(--301-white);
}

button._301_cta._301_delete
{
    width: 35px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}

button._301_cta._301_delete i
{
    margin: 0;
}

._301_cta-fsize li a, ._301_cta-fsize li button{
    font-size: 16px;
}

.grecaptcha-badge 
{
    visibility: hidden;
}

.owl-carousel._301_default button.owl-dot, .owl-carousel._301_property button.owl-dot
{
    background: var(--301-white);
    width: 6px;
    height: 6px;
    border-radius: 30px;
    margin: 0 6px;
}

.owl-carousel._301_default button.owl-dot.active, .owl-carousel._301_property button.owl-dot.active
{
    opacity: .2;
    width: 25px;
}

.owl-carousel._301_default .owl-stage, .owl-carousel._301_property .owl-stage
{
    margin: 0 auto;
}

.owl-dots
{
    margin: auto;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
}

.owl-nav
{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
    width: 98%;
}

.owl-nav span
{
    background: url(../public/img/301_arrow.svg) no-repeat right center;
    color: transparent;
    font-size: 30px;
    line-height: 1;
}

.owl-nav .owl-prev
{
    transform: rotate(180deg);
}

.owl-nav  .owl-next
{
    float: right;
}

.owl-carousel._301_alter button.owl-dot
{
    background: var(--301-blue);
}

.owl-carousel._301_alter .owl-dots 
{
    position: relative;
    bottom: -20px;
}

._301_search .owl-stage{
    margin: 0 auto;
}

._301_show
{
    display: block!important;
}

._301_modal_content
{
    padding: 5px;
    text-align: center;
    display: block;
    background: white;
    color: black;
}

._301_hover a:hover,
._301_hover a:hover *
{
    color: var(--301-red);
}

._301_hover._301_alter a:hover,
._301_hover._301_alter a:hover *
{
    color: var(--301-blue);
}

._301_share ul
{
    margin: 0;
    list-style: none;
    padding: 0;
}

._301_share li
{
    list-style: none;
    display: inline-block;
    vertical-align: bottom;
    padding: 0 10px 0 0;
    margin: 0;
}

._301_share a
{
    font-size: var(--301-text-small-size);
}

._301_share button
{
    border: none;
    background: none;
    cursor: pointer;
    font-size: var(--301-text-small-size);
    padding: 0;
}

._301_share button._301_added
{
    text-decoration: line-through;
}

._301_share li i
{
    margin-right: 5px;
    color: var(--301-red);
}

._301_number
{
    font-size: var(--301-text-title-size);
    color: var(--301-blue);
    font-family: --301-bold;
}

._301_modal
{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11;   
    width: 80%;
    height: fit-content;
    margin: auto;
    right: 0;
    bottom: 0;
}

._301_modal ._301_content
{
    width: 100%;
}

._301_modal:before
{
    content: " ";
    background: var(--301-black-35);
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

._301_close_modal
{
    color: var(--301-red);
    cursor: pointer;
    transition: var(--301-transition);
    border: 1px solid var(--301-red);
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: var(--301-text-medium-size);
    height: 50px;
    border-radius: 50%;
    background: var(--301-white);
    width: 50px;
    line-height: 52px;
    text-align: center;
    z-index: 3;
}

._301_location_modal ._301_modal_content
{
    height: 400px;
}

._301_description_modal ._301_modal_content,
._301_share_modal ._301_modal_content
{
    padding: 25px;
}

._301_share_modal button
{
    margin: 0 5px;
}

._301_share_modal button circle
{
    fill: var(--301-blue);
}

._301_gallery_modal .owl-carousel .owl-item
{
    height: 0;
}

._301_gallery_modal .owl-carousel .owl-item.active
{
    height: auto;
}

._301_gallery_modal ._301_bg
{
    height: 700px;
    background-size: contain;
} 

._301_gallery_modal ._301_slide:after
{
    display: none;
}

._301_checkbox
{
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

body ._301_checkbox label,
body ._301_checkbox input
{
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    font-weight: 100;
}

body ._301_checkbox input
{
    margin: 0 5px;
}

._301_table 
{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
}

._301_table ._301_item
{
    margin-bottom: 30px;
}

._301_table p
{
    margin: 0 0 5px 0;
    font-weight: bold;
}

._301_table ul 
{
    margin: 0;
}

._301_table ._301_item i
{
    font-size: var(--301-text-small-size);
    color: var(--301-red);
}

._301_table ul li
{
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0 3px 0 0;
    background: var(--301-red);
    color: var(--301-red);
    border-radius: 50%;
    width: var(--301-text-small-size);
    height: var(--301-text-small-size);
    font-size: var(--301-text-table-size);
    line-height: var(--301-text-small-size);
    text-align: center;
    font-weight: bold;
}

._301_table ul li:last-child
{
    color: var(--301-white);
}

._301_accordion 
{
    margin-bottom: 50px;
    transition: var(--301-transition);
}

._301_accordion:last-child
{
    margin: 0;
}

._301_accordion h4
{
    font-size: var(--301-text-title-small-size);
    padding: 0 0 10px 0;
    border-bottom: 1px solid var(--301-white);
    /* background: url(../public/img/301_arrow_bottom.svg) no-repeat right center; */
    background-size: 12px 12px;
    cursor: pointer;
    transition: var(--301-transition);
}

._301_accordion h4:hover,
._301_accordion._301_active h4
{
    border-color: var(--301-red);
    /*background-image: url(../public/img/301_arrow_top.svg)*/
}

._301_accordion ._301_table
{
    display: none;
    column-gap: 10%;
}

._301_accordion._301_active ._301_table
{
    display: grid;    
}

._301_form
{
    display: flex;
}

._301_form ._301_row
{    
    display: inherit;
    margin: 8px;
    flex: 1;
}

._301_form ._301_row_2
{
    flex: 2;
    margin: 8px;
}

._301_form ._301_row:first-child
{
    margin-left: 0;
}

._301_form ._301_row:last-child
{
    margin-right: 0;
}

._301_form_send label
{
    margin: 25px 0 5px 0;
    display: block;
    font-weight: bold;
}

._301_form_send
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    text-align: left;
}

._301_form_send input[type=button]
{
    width: 120px;
    margin-top: 30px;
}

._301_form_send span
{
    color: var(--301-gray);
    margin-top: 5px;
    display: inline-block
}

._301_form_send textarea
{
    height: 60px;
    resize: none;
}

._301_range ._301_control
{
    display: inline-block;
    width: 48%;
    position: relative;
    margin-right: 2%;
}

._301_range ._301_control:nth-child(2)
{
    margin: 0 0 0 2%;
}

._301_range ._301_control span
{
    position: absolute;
    top: -7px;
    left: 6px;
    background: var(--301-white);
    font-size: 10px;
    padding: 2px;
    color: var(--301-gray);
}

._301_range .multi-range-slider .bar-left
{
    padding: 2px 0;
}

._301_range .multi-range-slider
{
    border: 0;
    box-shadow: none;
    font-family: --301-light;
    padding: 0;
    display: block;
    margin-top: 8px;
}

._301_range .multi-range-slider .bar-inner
{
    background-color: var(--301-red);
    border: 0;
    box-shadow: none;
}

._301_range .multi-range-slider .thumb:before
{
    border: 1px solid var(--301-gray);
    height: 6px;
    margin: 0;
    width: 22px;
    box-shadow: none;
    display: block;
    position: relative;
    border-radius: 0;
}

._301_range .multi-range-slider .thumb .caption *
{
    background-color: var(--301-red);
    border-radius: 3px;
    box-shadow: none;
    color: var(--301-white);
    font-size: 80%;
    padding: 0 10px;
}

._301_range .multi-range-slider .labels
{
    margin-bottom: 0;
    margin-top: 7px;
    padding: 0;
    line-height: 1;
    font-size: 70%;
}

._301_range .multi-range-slider .label
{
    color: var(--301-gray);
}

._301_range .multi-range-slider .bar-left,
._301_range .multi-range-slider .bar-right
{
    box-shadow: none;
    background: var(--301-gray);
    border-radius: 0;
}

._301_range .multi-range-slider .input-type-range:hover+.thumb .caption,
._301_range .multi-range-slider .thumb:hover .caption
{
    display: flex;
}

._301_tabs ._301_options a
{
    background: var(--301-gray);
    border-radius: 5px 5px 0 0;
    color: #fff;
    color: var(--301-white);
    cursor: pointer;
    display: inline-block;
    padding: 5px 12px;
}

._301_tabs ._301_options a._301_active,
._301_tabs ._301_options a:hover
{
    background: var(--301-red);
    color: var(--301-white);
}

._301_tabs ._301_tab._301_active
{
    display: block;
}

._301_content
{
    width: 90%;
    margin: 0 auto;
}

._301_content._301_alter
{
    width: 70%;
}

._301_float
{
    background: var(--301-white);
    margin-top: -60px;
    position: relative;
    z-index: 2;
    padding: 30px;
}

._301_float p
{
    margin: 0;
}

._301_bg
{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;    
}

._301_bg_alter
{
    background-color: var(--301-beige);
}

._301_flex,
._301_flex_gap
{
    display: flex;
    align-items: center;
}

._301_content ._301_additional_data {
    align-items: start;
}

._301_flex_gap
{   
    column-gap: 6em;
}

._301_flex_gap ._301_col:first-child
{
    text-align: right;
}

._301_col
{
    flex: 1;
}

._301_padding_50
{
    padding: 50px 0;
}

._301_padding_100
{
    padding: 100px 0;
}

._301_padding_150
{
    padding: 150px 0;
}

._301_option
{
    display: inline-block;
}

._301_option label
{
    text-transform: uppercase;
    margin: 0 6px;    
    color: var(--301-white);
    font-size: var(--301-text-small-size);
}

._301_option ._301_icon
{
    display: inline-block;
    vertical-align: middle;
    background-color: var(--301-red);
    color: var(--301-white);
    border-radius: 50%;
    cursor: pointer;
    transition: var(--301-transition);
    border: 1px solid var(--301-red);
    width: 48px;
    height: 48px;
    line-height: 1;
    text-align: center;
}

._301_option ._301_icon i
{
    line-height: 48px;
}

._301_option._301_small ._301_icon
{
    width: 28px;
    height: 28px;
    font-size: var(--301-text-small-size);
}

._301_option._301_small ._301_icon i
{
    line-height: 28px;
}

._301_option ._301_icon:hover
{
    background-color: var(--301-white);
    color: var(--301-red);
}

._301_option button{
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    cursor: pointer;
    padding: 0;
}

._301_option a, ._301_option button
{
    padding: 0 0 2px 0;
    border-bottom: 2px solid var(--301-red);
    margin-left: 5px;
}

._301_option._301_small a, 
._301_option._301_small span, 
._301_option._301_small button
{
    border-bottom: 0;
    font-size: var(--301-text-small-size);
}

._301_option a:hover, ._301_option span:hover, ._301_option button:hover
{
    color: var(--301-red); 
}

._301_option._301_alter ._301_icon
{
    background-color: var(--301-white);
    color: var(--301-red);
    border-color: var(--301-gray);
}

._301_option._301_alter ._301_icon i
{
    color: var(--301-red);
}

._301_option._301_alter ._301_icon:hover
{
    background-color: var(--301-red);
    color: var(--301-white);
    border-color: var(--301-red);
}

._301_option._301_alter ._301_icon:hover i
{
    color: var(--301-white);
}

._301_line
{
    position: relative;
}

._301_line:before
{
    content: " ";
    position: absolute;
    top: -10px;
    width: 60px;
    height: 2px;
    background: var(--301-red);
}

._301_title_move h2:nth-child(2)
{
    margin-left: 50px;
}

._301_title_move h2:nth-child(3)
{
    margin-left: 20px;
}

header
{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 15px 0;
    z-index: 10;
    transition: var(--301-transition);
}

header._301_active
{
    background-color: var(--301-white);
    box-shadow: 0px 0px 4px var(--301-gray);
}

header._301_active ._301_call_cta
{
    background: var(--301-beige);
}

._301_toolbar
{
    z-index: 10;
    position: relative;
}

._301_toolbar._301_alter ._301_option label,
._301_toolbar._301_alter_fix ._301_option label
{
    color: var(--301-blue);
}

._301_toolbar._301_alter ._301_logo,
._301_toolbar._301_alter_fix ._301_logo
{
    background-image: url(../public/img/301_logo_alter.svg);
}

._301_toolbar._301_alter_fix ._301_call_cta
{
    border-color: var(--301-blue);
}

._301_toolbar._301_alter._301_alter_fix ._301_call_cta
{
    border-color: var(--301-white);
}

._301_logo
{
    background: url(../public/img/301_logo.svg) no-repeat center;
    width: 70px;
    height: 70px;
    background-size: contain;
    margin: 0 auto;
    cursor: pointer;
}

._301_logo_alter
{
    background: url(../public/img/301_logo_alter.svg) no-repeat center;
    width: 70px;
    height: 70px;
    background-size: contain;
    margin: 0 auto;
    cursor: pointer;
}

._301_logo:hover
{
    background-image: url(../public/img/301_logo_alter.svg)
}

._301_menu
{
    text-align: right;
}

._301_menu ._301_icon .fa-xmark
{
    display: none;
}

._301_menu ._301_icon._301_close .fa-bars
{
    display: none;
}

._301_menu ._301_icon._301_close .fa-xmark
{
    display: initial;
}

._301_menu_view
{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--301-beige);
    text-align: center;
    z-index: 9;
    padding-top: 200px;
}

._301_menu_view ul
{
    margin: 0;
}

._301_menu_view ._301_category
{
    margin: 0 0 30px 0;
}

._301_menu_view ._301_category:last-child
{
    margin: 0;
}

._301_menu_view ._301_category h3
{
    font-size: var(--301-text-title-size);
}

._301_menu_view ._301_category li
{
    display: inline-block;
    margin: 0 5px;
    list-style: none;
    border-right: 1px solid;
    padding-right: 10px;
    padding-inline-start: 0;
}

._301_menu_view ._301_category li:last-child
{
    margin: 0;
    padding: 0;
    border: 0;
}

._301_submenu
{
    margin: 0 auto;
    width: fit-content;
}

._301_submenu h4
{
    font-size: var(--301-text-title-small-size);
}

._301_submenu ._301_options
{
    margin: 20px 0 0 0;
    padding: 10px 0 0 0;
}

._301_submenu ._301_options ul
{
    margin: 0;
}

._301_submenu ._301_options ul,
._301_submenu ._301_options li
{
    list-style: none;
    padding: 0;
}

._301_submenu ._301_options li i
{
    display: inline-block;
    vertical-align: baseline;
    margin-right: 5px;
}

._301_call_cta
{
    display: inline-block;
    background: var(--301-white);
    padding: 8px 20px;
    margin: 0 8px;
    border-radius: 50px;
    transition: var(--301-transition);
    cursor: pointer;
    vertical-align: middle;
    border: 1px solid var(--301-white);
}

._301_call_cta label, ._301_call_cta i
{
    cursor: pointer;
    transition: var(--301-transition);
    font-size: var(--301-text-small-size);
}

._301_call_cta:hover *
{
    color: var(--301-red);
}

._301_call_cta._301_fav
{
    padding: 8px 12px;
}

._301_slide
{
    position: relative;
    overflow: hidden;
}

._301_slide:after
{
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--301-black-35);
    width: 100%;
    height: 100%;
    z-index: 2;
}

._301_banner ._301_text
{
    position: absolute;
    z-index: 3;
    width: 50%;
    height: fit-content;
    overflow: hidden;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    padding: 0 0 5px 0;
}

._301_banner ._301_text *
{
    color: var(--301-white);
}

._301_banner ._301_text h2
{
    font-size: var(--301-text-big-size);
    line-height: 1;   
}

._301_banner ._301_text ._301_cta
{
    background: url(../public/img/301_arrow.svg) no-repeat right center;
    padding: 5px 20px 5px 0;    
    border-radius: 25px;
    background-size: 15px 15px;
}

._301_banner ._301_text ._301_cta:hover
{
    color: var(--301-red);
    background-color: var(--301-white);
    padding: 5px 20px 5px 10px;
}

._301_banner img
{
    display: block;
}

._301_numbers
{
    padding: 30px 0;
    background-color: var(--301-white-30);
    text-align: right;
}

._301_numbers li
{
    display: inline-block;
    vertical-align: bottom;
    margin: 0 15px;
    border-right: 2px solid var(--301-white);
    text-align: center;
    padding: 0 30px 0 0;
}

._301_numbers li:first-child
{
    margin-left: 0;
    padding-left: 0;
}

._301_numbers li:last-child
{
    margin-right: 0;
    padding-right: 0;
    border: 0;
}

._301_search_bar ._301_tabs
{
    padding: 50px 0;
}

._301_search_bar ._301_tabs ._301_form
{
    align-items: baseline;
}

._301_search_bar ._301_tab
{
    border: 1px solid var(--301-gray);
    padding: 10px;
    border-radius: 0 5px 5px 5px;
}

._301_about ._301_brand
{
    margin: 0 auto;
    width: 120px;
}

._301_about ._301_since ._301_option
{
    margin: 50px 0 0 50px;
}

._301_best .owl-nav
{
    width: 98%;
}

._301_best .owl-nav span
{
    background-image: url(../public/img/301_arrow_alter.svg)
}

._301_best ._301_slide ._301_text
{
    width: 80%;
}

._301_best ._301_col:first-child ._301_text
{
    text-align: left;
}

._301_best ._301_col:last-child ._301_text
{
    text-align: right;
}

._301_best ._301_text h2
{
    font-size: var(--301-text-medium-size);
}

._301_best ._301_bg
{
    height: 400px;
}

._301_counter ._301_item
{
    text-align: center;
    margin: 0 40px;
}

._301_counter ._301_number
{
    padding: 0 0 20px 0;
    margin: 0 auto 40px auto;
    border-bottom: 2px solid var(--301-red);
    width: 60px;
}

._301_counter p
{
    margin: 0;
}

._301_adviser ._301_copy
{
    padding-top: 50px;
}

._301_info_body ._301_map
{
    min-width: 100px;
    width: 100%;
    height: 300px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--301-red);
}

._301_news
{
    position: relative;
}

._301_news ._301_float
{
    position: absolute;
    width: 70%;
    top: 0;
    z-index: 5;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 57px;
}

._301_news ._301_flex_new 
{
    display: flex;                   
    flex-direction: row;             
    justify-content: center;
    align-items: center;       
}

._301_news ._301_flex_new ._301_item
{
    flex: 1;
    margin: 0 7px;
}

._301_news ._301_flex_new_row
{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

._301_news ._301_flex_new_row ._301_item
{
    margin: 7px;
}

._301_news ._301_item ._301_text
{
    text-align: left;
    right: auto;
    bottom: auto;
    height: 100px;
}

._301_news ._301_flex_new > ._301_item ._301_text
{
    left: 10%;
    top: 15%;
    padding-bottom: 5px;      
}

._301_news ._301_flex_new > ._301_item ._301_bg
{
    height: 600px;
}

._301_news ._301_flex_new_row ._301_item:nth-child(1) ._301_bg,
._301_news ._301_flex_new_row ._301_item:nth-child(2) ._301_bg
{
    height: 293px;
}

._301_news ._301_flex_new_row ._301_item:nth-child(1) ._301_text
{
    left: auto;
    top: 20%;
    right: 10%;    
}

._301_news ._301_flex_new_row ._301_item:nth-child(2) ._301_text
{
    left: 10%;
    top: 40%;   
}

._301_legal
{
    background-color: var(--301-red);
    padding: 15px 0;
}

._301_legal *
{
    color: var(--301-white);
}

._301_legal ._301_col:nth-child(2)
{
    text-align: right;
}

._301_legal ul
{
    margin: 0;
    display: inline-block;
}

._301_legal ul li
{
    display: inline-block;
    padding: 0;
    margin-left: 7px;
    padding-left: 7px;
    border-left: 1px solid var(--301-white);
}

._301_legal ._301_social li
{
    border: 0;
}

._301_legal ._301_social li a
{
    font-size: 18px;
}

._301_legal ._301_social li:first-child
{
    border: 0;
    padding: 0;
    margin: 0;
}

._301_legal ._301_chat 
{
    position: fixed;
    bottom: 4%;
    right: 1%;
    z-index: 10;
    display: none;
}

._301_grid ._301_flex
{
    column-gap: 25px;
    position: relative;
    align-items: start;
}

._301_grid ._301_col
{
    flex: 1 1 auto;
}

._301_grid ._301_col:nth-child(1)
{
    width: 30%;
}

._301_grid ._301_col:nth-child(2)
{
    width: 70%;
}

._301_grid ._301_flex._301_sort
{
    padding: 100px 0 50px 0;
    align-items: center;
}

._301_grid ._301_sort select
{
    width: auto;
}

._301_grid ._301_sort ._301_cta
{
    color: var(--301-red);
    cursor: pointer;
    transition: var(--301-transition);
    display: none;
}

._301_grid ._301_cta:hover
{
    color: var(--301-blue);
}

._301_grid ._301_order
{
    text-align: right;
}

._301_grid ._301_filters
{
    background: var(--301-beige);
}

._301_grid ._301_filters ._301_close_modal
{
    display: none;       
}

body ._301_grid ._301_filter_mobile_open {
    display: block;
    opacity: 1;
}

._301_grid ._301_filters ._301_options
{
    background: var(--301-white);
}

._301_grid ._301_filters label
{
    font-weight: bold;
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
}

._301_grid ._301_filters ._301_row 
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
    align-items: center;
}

._301_grid ._301_filters [type=checkbox]
{
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
}

._301_grid ._301_filters .status
{
    padding: 20px 20px 0 20px;
}

._301_grid ._301_filters ._301_clear ._301_row
{
    grid-template-columns: repeat(1,1fr);
}

._301_grid ._301_filters ._301_clear label
{
    margin-bottom: 10px;
}

._301_grid ._301_filters ._301_features,
._301_grid ._301_filters ._301_multi_check
{
    padding: 0 20px;   
}

._301_grid ._301_filters ._301_col
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-bottom: 5px;
    align-items: center;
    position: relative;
    border-bottom: 1px solid var(--301-white);
    height: 35px;
    padding-bottom: 5px;
}

._301_grid ._301_filters .status > ._301_row:first-child
{
    grid-template-columns: repeat(3, 1fr);
}

._301_grid ._301_filters ._301_col
{
    width: 100%;
}

._301_grid ._301_filters p
{
    text-transform: uppercase;
    font-family: '--301-bold';
    margin-bottom: 10px;
    padding: 20px 20px 10px 20px;
    text-transform: uppercase;
    border-color: var(--301-red);
    background: url(../public/img/301_arrow_top.svg) no-repeat 95% 24px;
    background-size: 12px 12px;
    border-bottom: 1px solid var(--301-red);
    cursor: pointer;
    transition: var(--301-transition);
}

._301_grid ._301_filters input,
._301_grid ._301_filters select
{
    background: none;
    font-size: 12px;
}

._301_grid ._301_filters ._301_features input[type=text],
._301_grid ._301_filters ._301_features button
{
    display: inline-block;
    vertical-align: middle;    
}

._301_grid ._301_filters ._301_sum
{
    text-align: center;
}

._301_grid ._301_filters ._301_sum div
{
    background: no-repeat;
    border: 1px solid #a5a6a3;
    border: 1px solid var(--301-gray);
    border-radius: 50%;
    color: #a5a6a3;
    color: var(--301-gray);
    font-size: 12px;
    font-weight: 100;
    height: 18px;
    line-height: 18px;
    text-align: center;
    width: 18px;
    cursor: pointer;
    display: inline-block;
}

._301_grid ._301_filters ._301_sum input
{
    border: 0;
    width: 14px;
    text-align: center;
    padding: 0;
    margin: 0 1px;
}

._301_grid ._301_filters ._301_sum div._301_more
{
    color: var(--301-red);
    border-color: var(--301-red);
}

._301_grid ._301_filters ._301_accordion
{
    margin: 0;
}

._301_grid ._301_filters ._301_accordion._301_collapse ._301_row
{
    display: none;
}

._301_grid ._301_filters ._301_accordion._301_collapse p
{
    background-image: url(../public/img/301_arrow_bottom.svg);
    border-bottom: 1px solid var(--301-white);
}

._301_grid ._301_filters ._301_range ._301_control span
{
    background: var(--301-beige);
}

._301_grid ._301_item 
{
    border: 1px solid var(--301-gray);
    margin-bottom: 25px;
}

._301_grid ._301_item:last-child
{
    margin: 0;
}

._301_grid ._301_item ._301_col:nth-child(1)
{
    width: 60%;
}

._301_grid ._301_item ._301_col:nth-child(2)
{
    width: 40%;
}

._301_grid ._301_detail a h5
{
    margin: 0;   
}

._301_grid ._301_detail h2
{
    font-size: var(--301-text-medium-size);
}

._301_grid ._301_detail h4
{
    font-family: --301-light;
    font-weight: bold;
    font-size: var(--301-text-size);
}

._301_grid ._301_detail p
{
    margin: 0 0 10px 0;
}

._301_grid ._301_detail p._301_new
{
    margin: 25px 0;
}

._301_grid ._301_detail p span
{
    display: block;
    text-transform: uppercase;
    font-style: italic;
}

._301_grid ._301_detail ul
{
    margin:20px 0 20px 12px;
}

._301_grid ._301_data
{
    padding: 25px 25px 25px 0;
}

._301_grid ._301_data a:hover h2
{
    color: var(--301-red);
}

._301_grid ._301_data li
{
    margin: 5px 0;
}

._301_grid ._301_slide
{
    height: 284px;
    width: 100%;
}

._301_grid ._301_slide ._301_bg
{    
    width: 100%;
    height: 100%;
}

._301_grid ._301_pager
{
    margin: 50px 0 0 0;
}

._301_grid._301_fav
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 50px;
}

._301_grid._301_fav ._301_data
{
    padding: 0 20px 0 0;
}

._301_grid._301_fav ._301_item
{
    margin: 30px 0;
    position: relative;
}

._301_info_head
{
    margin-top: 150px;
    background: var(--301-gray-clean);
    padding-left: 5%;
}

._301_info_head ._301_data
{
    width: 30%;
    flex: 30%;
    padding: 0 50px 0 0;
}

._301_info_head ._301_banner
{
    width: 70%;
    flex: 70%;
}

._301_info_head ._301_banner ._301_bg
{
    height: 450px;    
}

._301_info_head ._301_detail p
{
    margin: 5px 0;
}

._301_info_head ._301_detail p > span
{
    display: block;
    text-transform: uppercase;
    font-style: italic;
}

._301_info_head ._301_detail h4
{
    font-size: var(--301-text-medium-size);
    font-weight: bold;
}

._301_info_head ._301_detail h5 
{
    font-family: --301-bold;
    font-size: var(--301-text-small-size);
    line-height: 1;
    margin: 5px 0;
}

._301_info_head ._301_share
{
    margin-top: 30px;
}

._301_info_head ._301_text
{        
    margin: 30px 0;
}

._301_info_head ._301_text p
{
    margin: 0;
}

._301_info_head ._301_text button
{
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    cursor: pointer;
    padding: 0;
}

._301_info_head ._301_text a, ._301_info_head ._301_text button
{
    font-size: var(--301-text-small-size);
    border-bottom: 1px solid var(--301-red);
    margin-left: 5px;
}

._301_info_head ._301_text a:hover, ._301_info_head ._301_text button:hover
{
    color: var(--301-red);
}

._301_info_head ._301_option
{
    margin-right: 20px;
}

._301_info_head ._301_option:last-child
{
    margin: 0;
}

._301_info_body ._301_table
{
    width: 80%;
    margin: 50px auto;
}

._301_info_body ._301_data
{
    flex: 2;
    margin-right: 10%;
}

._301_info_body ._301_people ._301_bg
{
    height: 250px;
    width: 200px;
    background-size: contain;
    background-position: 0 0;
}

._301_info_body ._301_people ul
{
    margin: 0;
}

._301_info_body ._301_people li
{
    list-style: none;
    padding: 0;
}

._301_info_body ._301_people h3
{
    font-size: var(--301-text-title-small-size);
}

._301_info_body ._301_people span
{
    color: var(--301-red);
    display: inline-block;
    width: 20px;
    margin-right: 2px;
}

._301_general
{
    padding: 50px 0;
    background: var(--301-beige);
}

._301_general h1,
._301_general h2,
._301_general h3,
._301_general h4
{
    margin: 50px 0 20px 0;
    font-size: var(--301-text-medium-size);
}

._301_general ul
{
    margin: 30px;
}

._301_general p a,
._301_form_send a
{
    color: var(--301-red);
    text-decoration: underline;
    font-weight: 100;
}

._301_pagination
{
    text-align: center;
}

._301_page_number
{
    display: inline-block;
    cursor: pointer;
}

._301_page_number._301_active
{
    color: var(--301-red);
}

._301_lang_active{
    color: var(--301-red);
}

._301_floating_block
{
    background-color: var(--301-beige);
    display: none;
    position: absolute;
    padding: 10px 20px;
    border-radius: 8px;
    top: 70px;
}

._301_floating_block._301_active
{
    display: block;
}

._301_floating_block a
{
    border: 0;
    margin: 5px 0;
    display: block;
}

._301_floating_block ._301_cta 
{
    background: var(--301-red);
    padding: 0;
    margin: 0;
    border: 1px solid;
}

._301_content._301_news ._301_detail h2
{
    font-size: 18px;
    margin-bottom: 10px;
}

._301_content._301_news ._301_item ._301_flex
{
    align-items: center;
}

._301_toast
{
    position: fixed;
    bottom: 150px;
    right: 0;
    z-index: 10;
    background: var(--301-red);
    padding: 10px;
    color: var(--301-white);
    opacity: .8;
}

._301_toast p
{
    color: var(--301-white);
}

._301_autocomplete{
    width: 100%;
}

._301_autocomplete ul
{
    position: absolute;
    border: 1px solid var(--301-gray);
    margin: 0;
    border-radius: 4px;
    background: var(--301-white);
    z-index: 4 ;
}

._301_autocomplete li
{
    position: relative;
    list-style: none;
    padding: 5px 5px;
    margin: 0;
}

._301_autocomplete li:hover
{
    background: var(--301-beige);
    cursor: pointer;
}

._301_grid ._301_card ._301_detail ul
{
    margin: 20px 0;
}

._301_grid ._301_card ._301_detail li,
._301_info_head ._301_icons li
{
    list-style: none;
    padding: 0;
}

._301_info_head ._301_icons
{
    margin: 0;
    padding: 0;
}

._301_info_head ._301_icons li
{
    display: inline-block;
    vertical-align: bottom;
    margin: 0 15px 0 0;
}

._301_info_head ._301_icons li i
{
    margin: 0;
}

._301_grid ._301_card ._301_detail li i,
._301_info_head ._301_icons li i
{
    color: var(--301-red);
    font-size: 10px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
}

._301_fm_call ._301_info_head ._301_data
{
    padding: 30px 0;
    width: 100%;
}

._301_property_carousel ._301_bg ._301_zoom
{
    position: absolute;
    z-index: 3;
    right: 10px;
    top: 10px;
    background: no-repeat;
    border: 0;
    color: var(--301-white);
    cursor: pointer;
    outline: none;
    font-size: 18px;
}

._301_info_head ._301_banner ._301_property_carousel ._301_bg
{
    height: 550px;
}

.pswp .pswp__counter
{
    font-family: '--301-light';
}

.pswp .pswp__button--close,
.pswp .pswp__button--close:hover
{
    margin: 0;
    background: var(--301-white);
    border: 1px solid var(--301-red);
    border-radius: 50%;
    color: var(--301-red);
    cursor: pointer;
    font-size: var(--301-text-medium-size);
    height: 50px;
    right: 10px;
    position: absolute;
    text-align: center;
    top: 10px;
    transition: all .5s;
    transition: var(--301-transition);
    width: 50px;
    z-index: 3;
    opacity: 1;
}

.pswp .pswp__button--close .pswp__icn
{
    fill: var(--301-red);
    height: 28px;
    left: 0;
    top: 0;
    width: 28px;
    margin: auto;
    bottom: 0;
    right: 0;
}

.pswp .pswp__icn-shadow
{
    stroke-width: 0;
}

.pswp .pswp__bg
{
    background: #4f4f4f;
}

button.pswp__button.pswp__button--zoom
{
    display: none !important;
}

h2._301_cta_int
{    
    margin: 0 15px;
    text-align: center;
}

h2._301_cta_int a
{    
    border: 1px solid var(--301-red);
    padding: 30px;
    cursor: pointer;
    line-height: 1;
    font-weight: bold;
    display: block;
    transition: all .5s;
    box-shadow: 1px 1px 3px #cecbbc;
    border-radius: 4px;
}

h2._301_cta_int a:hover
{
    background: var(--301-red);
    color: var(--301-white);
}


footer ._301_whatsapp {
    width: 45px;
    height: 45px;
    background: #25D366;
    font-size: 25px;
    text-align: center;
    padding: 5px;
    line-height: 45px;
    border-radius: 100% 100% 0 100%;
    cursor: pointer;
    box-shadow: 0px 0px 3px #25D366;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 3;
}

footer ._301_whatsapp *
{
    color: var(--301-white);
    font-size: var(--301-text-medium-size);
}

@media (min-width: 2500px)
{
    html
    {
        zoom: 1.4;
    }
}

@media (max-width: 1200px)
{
    ._301_flex_gap
    {
        column-gap: 2em;
    }

    ._301_counter ._301_item
    {
        margin: 0 20px;
    }

    ._301_legal
    {
        padding: 20px 0;
    }

    ._301_legal ._301_flex
    {
        flex-direction: column-reverse;
    }

    ._301_legal ._301_copyr
    {
        display: block;
        margin: 15px 0 0 0;
    }

    ._301_legal ._301_chat
    {
        bottom: 2%;
        right: 2%;
    }

    ._301_grid ._301_sort ._301_cta
    {
        display: block;
    }

    ._301_grid ._301_filters
    {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
        height: 100%;
        display: none;
        overflow: scroll;
    }

    ._301_grid ._301_filters:after
    {
        content: " ";
        z-index: -1;
        background: var(--301-black-35);
        width: 20%;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
    }

    ._301_grid ._301_filters ._301_options
    {
        padding: 100px 20px 0 20px;
    }

    ._301_news ._301_grid ._301_content ._301_filters
    {
        padding-top: 100px;
    }

    ._301_grid ._301_filters ._301_close_modal
    {
        display: block;
        left: auto;
        right: 10px;
        top: 10px;  
    }    

    ._301_info_head
    {
        flex-direction: column;
        padding: 0 5%;
    }
    
    ._301_info_head ._301_data
    {
        width: 100%;
        padding: 50px 0;
    }

    ._301_info_head ._301_text
    {        
        margin: 50px 0;
    }

    ._301_info_head ._301_banner
    {
        width: 100%;
        margin-bottom: 50px;
    }

    body ._301_info_head ._301_banner ._301_bg
    {
        height: 400px;
    }

    ._301_info_body ._301_flex
    {
        flex-direction: column;
    }
    
    ._301_info_body ._301_data
    {
        margin: 0 0 50px 0;
        width: 100%;
    }

    ._301_info_body ._301_table
    {
        width: 90%;
    }

    ._301_info_body ._301_adviser
    {
        width: 100%;
    }

    ._301_form_send
    {
        grid-template-columns: repeat(1, 1fr);
    }

    ._301_gallery_modal ._301_bg
    {
        height: 500px;
    }

    ._301_grid._301_fav
    {
        grid-template-columns: repeat(1,1fr);
    }

    ._301_grid._301_fav ._301_item ._301_col
    {
        width: 100%;
    }

    ._301_grid._301_fav ._301_data
    {
        padding: 20px;
    }

    ._301_grid._301_fav ._301_detail h2
    {
        margin: 20px 0;
    }

    ._301_search_bar ._301_tabs
    {
        padding: 100px 0;
    }    

    ._301_fm_call ._301_general ._301_flex
    {
        display: block;
    }

    ._301_general ._301_flex_gap ._301_col
    {
        width: 100%;
    }

    ._301_login ._301_form_send input[type=button]
    {
        margin-top: 30px;
        width: 120px;
    }

    ._301_grid ._301_flex._301_sort
    {
        padding: 50px 0;
    }

    h2._301_cta_int
    {
        margin: 20px 0;
    }

    ._301_range .multi-range-slider .thumb:before
    {
        height: 12px;
    }
}

@media (max-width: 900px)
{    
    header ._301_option label
    {
        display: none;
    }

    header._301_active ._301_call_cta
    {
        background: var(--301-white);
    }

    ._301_call_cta._301_fav
    {
        padding: 0
    }

    ._301_call_cta    
    {
        padding: 0;
        width: 48px;
        height: 48px;
        line-height: 1;
        text-align: center;
        border: 1px solid var(--301-blue);
    }

    ._301_toolbar._301_alter._301_alter_fix ._301_call_cta
    {
        border-color: var(--301-blue);
    }

    ._301_call_cta label
    {
        display: none;
    }

    ._301_call_cta label,
    ._301_call_cta i
    {
        font-size: var(--301-text-size);
        line-height: 48px;
    }

    ._301_content._301_alter
    {
        width: 90%;
    }

    ._301_flex_gap
    {
        flex-direction: column;
        row-gap: 10px;     
    }

    ._301_flex_gap ._301_col:first-child
    {
        text-align: center;
    }    

    ._301_about
    {
        padding-top: 100px;
    }

    ._301_about ._301_brand
    {
        display: none;
    }

    ._301_about ._301_since ._301_option
    {
        margin: 2em 0 0 0;
    }

    ._301_numbers
    {
        text-align: center;
    }

    ._301_numbers ul
    {
        margin: 0;
    }

    ._301_float
    {
        width: 50%;
        margin: -20px auto 0 auto;
    }

    ._301_float._301_clear
    {
        margin: 50px auto 0 auto;
    }

    ._301_news ._301_float
    {
        position: relative;
        width: fit-content;
        top: auto;
        height: auto;
    }

    ._301_news ._301_flex_new_row ._301_item:nth-child(2) ._301_text
    {
        top: 30%;
    }

    ._301_grid ._301_item ._301_flex
    {
        flex-direction: column;
    }

    ._301_grid ._301_content ._301_item ._301_col
    {
        width: 100%;
    }

    ._301_grid ._301_data
    {
        padding: 30px;
    }

    ._301_form_send ._301_row:last-child ._301_checkbox
    {
        margin: 25px 0;
    }

    ._301_form_send input[type=button]
    {
        margin: 0;
        display: block;
    }
    
    ._301_news ._301_item ._301_text
    {
        height: auto;
    }

    ._301_search_bar ._301_tabs ._301_form
    {
        align-items: normal;
    }

    ._301_form ._301_row,
    ._301_form ._301_row_2
    {
        margin: 10px 0;
    }
}

@media (max-width: 760px)
{
    :root
    {
        --301-text-big-size: 34px;
    }

    body
    {
        padding-top: 120px;
    }

    header
    {
        padding: 25px 0;
    }   

    ._301_padding_150
    {
        padding: 100px 0;
    }

    ._301_logo
    {
        background-image: url(../public/img/301_logo_alter.svg);
    }

    ._301_menu_view
    {
        padding-top: 150px;
    }
    
    ._301_menu_view ._301_category h3
    {
        font-size: var(--301-text-medium-size);
    }

    ._301_menu_view ._301_category li,
    ._301_menu_view ._301_category li:last-child
    {
        margin: 5px 7px;
        border: 0;
        padding:0;
    }

    ._301_option ._301_icon, 
    ._301_call_cta
    {
        width: 38px;
        height: 38px;
    }

    ._301_option ._301_icon i,
    ._301_call_cta label,
    ._301_call_cta i
    {
        line-height: 38px;
    }

    ._301_banner ._301_default img
    {
        width: 200%;
        margin-left: -50%;
    }

    ._301_banner ._301_default ._301_text
    {
        width: 70%;
    }

    body ._301_info_head ._301_banner ._301_bg,
    ._301_info_head ._301_banner ._301_property_carousel ._301_bg
    {
        height: 220px;
    }

    ._301_form,
    ._301_best ._301_flex,
    ._301_news ._301_flex_new
    {
        flex-direction: column;
    }    

    ._301_numbers li
    {
        margin: 0 5px;
        padding: 0 10px 0 0;
    }

    ._301_best img
    {
        width: 150%;
    }

    ._301_adviser ._301_copy
    {
        padding-top: 30px;
    }

    ._301_news ._301_flex_new ._301_item
    {
        margin: 2%;
        width: 96%;
    }

    ._301_news ._301_flex_new_row 
    {
        width: 100%;
    }

    ._301_news ._301_flex_new > ._301_item ._301_bg
    {
        height: 293px;
    }

    ._301_legal
    {
        padding: 0;
    }

    ._301_legal ul
    {
        display: block;
    }

    ._301_legal ._301_social
    {
        margin: 50px 0;
    }

    ._301_legal ._301_col:nth-child(2)
    {
        text-align: center;
    }

    ._301_legal ._301_data li
    {
        display: block;
        border: 0;
        padding: 0;
        margin: 10px 0;
    }

    ._301_legal ._301_copyr
    {        
        margin: 50px 0;
    }

     ._301_legal ._301_chat
    {
        right: 5%;
    }

    ._301_grid ._301_content ._301_filters
    {
        width: 80%;
    }

    ._301_info_head
    {
        margin: 0;
    }
    
    ._301_info_body ._301_table
    {
        column-gap: 6%;
    }

    ._301_table ._301_item
    {
        flex: 47%;
    }

    ._301_info_body ._301_adviser img
    {
        width: 100%;
    }

    ._301_accordion ._301_table
    {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20%;
    }

    ._301_gallery_modal ._301_bg
    {
        height: 250px;
    }
}

.owl-carousel 
{
    touch-action: manipulation; 
}